import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { walletModalShow, connectWalletShow, connectWalletHide} from "../../redux/counterSlice";
import { useMetaMask } from "metamask-react";

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Link from "next/link";



export default function WalletButton() {
  const dispatch = useDispatch();
  const { status, connect, account, balance, chainId, ethereum } = useMetaMask();
  const [category, setCategory] = React.useState(null);

  const walletHandler = () => {
    if (status === "unavailable") {
      dispatch(walletModalShow());
    }
  };


  const connectWallet = () => {
    if (status === "notConnected") {
      dispatch(connectWalletShow());
    }
  };

  
  

  if (status === "initializing") 
    return (

      <div className="modal-body p-6 text-center">

            <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
            <img src="/images/wallets/metamask.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
            <span className="line-height-2">Loading..</span>
            </button>

            <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
              <img src="/images/wallets/trustwallet.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
              <span className="line-height-2">Trust Wallet</span>
            </button>

            <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
              <img src="/images/wallets/coinbase.svg" className="mr-2.5 inline-block h-8 w-8" alt="" />
              <span className="line-height-2">Coinbase Wallet</span>
            </button>

            <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
              <img src="/images/wallets/mathwallet.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
              <span className="line-height-2">Math Wallet</span>
            </button>

            <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
              <img src="/images/wallets/binance.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
              <span className="line-height-2">Binance Wallet</span>
            </button>
      </div>
  );

  if (status === "unavailable")
    return (

        <div className="modal-body p-6 text-center">

              <button  onClick={walletHandler} className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
              <img src="/images/wallets/metamask.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
              <span className="line-height-2">Metamask</span>
              </button>

              <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
                <img src="/images/wallets/trustwallet.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
                <span className="line-height-2">Trust Wallet</span>
              </button>

              <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
                <img src="/images/wallets/coinbase.svg" className="mr-2.5 inline-block h-8 w-8" alt="" />
                <span className="line-height-2">Coinbase Wallet</span>
              </button>

              <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
                <img src="/images/wallets/mathwallet.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
                <span className="line-height-2">Math Wallet</span>
              </button>

              <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
                <img src="/images/wallets/binance.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
                <span className="line-height-2">Binance Wallet</span>
              </button>
        </div>
     
    );

  if (status === "notConnected")
    return (

      <div className="modal-body p-6 text-center">

            <button  onClick={connect} className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
            <img src="/images/wallets/metamask.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
            <span className="line-height-2">Metamask</span>
            </button>

            <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
              <img src="/images/wallets/trustwallet.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
              <span className="line-height-2">Trust Wallet</span>
            </button>

            <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
              <img src="/images/wallets/coinbase.svg" className="mr-2.5 inline-block h-8 w-8" alt="" />
              <span className="line-height-2">Coinbase Wallet</span>
            </button>

            <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
              <img src="/images/wallets/mathwallet.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
              <span className="line-height-2">Math Wallet</span>
            </button>

            <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
              <img src="/images/wallets/binance.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
              <span className="line-height-2">Binance Wallet</span>
            </button>
      </div>
      
    );

  if (status === "connecting") 
  return (

      <div className="modal-body p-6 text-center">

          <button  className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
          <img src="/images/wallets/metamask.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
          <span className="line-height-2">Connecting...</span>
          </button>

          <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
            <img src="/images/wallets/trustwallet.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
            <span className="line-height-2">Trust Wallet</span>
          </button>

          <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
            <img src="/images/wallets/coinbase.svg" className="mr-2.5 inline-block h-8 w-8" alt="" />
            <span className="line-height-2">Coinbase Wallet</span>
          </button>

          <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
            <img src="/images/wallets/mathwallet.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
            <span className="line-height-2">Math Wallet</span>
          </button>

          <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-jacarta-100 text-jacarta-700 flex w-full mb-2 items-left justify-left bg-white py-2 px-8 text-left font-semibold transition-all hover:border-transparent dark:text-white dark:hover:border-transparent">
            <img src="/images/wallets/binance.svg" className="mr-2.5 inline-block h-8 w-8" alt=""/>
            <span className="line-height-2">Binance Wallet</span>
          </button>
    </div>

  );

  if (status === "connected") {
    //dispatch(connectWalletHide());
    console.log(ethereum);
  return(
    <div className="modal-body p-6 text-center">
        <TaskAltIcon width={50} height={50} />
        <p className="text-md font-bold ">Connection Succesful!</p>
        <p className="text-md">Wallet: {account}</p>
        <p className="text-md">Chain: {chainId}</p>
        <p className="text-md">{balance}</p>
        
    </div>
  )};

  return null;
}
