import React, { useEffect, useState } from 'react';
import Footer from "./footer";
import Wallet_modal from "./modal/wallet_modal";
import Connect_wallet from "./modal/connect_wallet";

import BidsModal from "./modal/bidsModal";
import BuyModal from "./modal/buyModal";
import Header01 from "./header/Header01";

export default function Layout({ children }) {
  const [search, setSearch] = useState('');
  return (
    <>
      <Header01 {...search}{...setSearch}/>
      <Wallet_modal />
      <Connect_wallet/>
      <BidsModal />
      <BidsModal />
      <BuyModal />
      <main>{React.cloneElement(children, [{...search}, {...setSearch}] )}</main>
      <Footer />
    </>
  );
}
