import dotenv from "dotenv";

dotenv.config();

const API_URL =  process.env.API_URL || "http://localhost:7000/api/v1";
const API_KEY =  process.env.API_KEY || "VveNzuFhuEURta14UvHTjIQNu.TjdGnZSpycs4qrUYMmUIINLDoY6DUChg.Dwgvl";

const IMAGE_URL =  process.env.IMAGE_URL || "http://localhost:3000/";
const DEFAULT_USER_IMAGE =  process.env.IMAGE_URL || "images/user/user_avatar.png";

const SERVICE_CHARGE =  5 ; //serviceFee commission(%) on orders 

export default {
  API_URL,
  API_KEY,
  IMAGE_URL,
  DEFAULT_USER_IMAGE,
  SERVICE_CHARGE,
};
