import React, { useEffect, useState } from 'react';
import Image from "next/image";
import Link from "next/link";
import DarkMode from "../mode/DarkMode";
import Logo from "./../../public/images/logo.png";
import WhiteLogo from "./../../public/images/logo_white.png";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import {
  isChildrenPageActive,
  isParentPageActive,
} from "../../utils/daynamicNavigation";
import WalletButton from "../wallet-btn/WalletButton";
import { services_data } from '../../data/services_data';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';


import { notification_data } from '../../data/notification_data';
import { inbox_data } from '../../data/inbox_data';
import { order_data } from '../../data/order_data';

import { useDispatch } from "react-redux";
import { walletModalShow, connectWalletShow} from "../../redux/counterSlice";
import { useMetaMask } from "metamask-react";

import  HeaderCategories  from './categories';



import axios from "axios";
import config from "../../utils/config.js";
import { register } from 'react-scroll/modules/mixins/scroller';

import { generateUsername } from "unique-username-generator";

import { ReactSession } from 'react-client-session';


export default function Header01() {

    const route = useRouter();
    const dispath = useDispatch();
    ReactSession.setStoreType("localStorage");
    const { status, connect, account, chainId, ethereum } = useMetaMask();

    const [toggle, setToggle] = useState(false);
    const [isScroll, setScroll] = useState(false);
    const [isCollapse, setCollapse] = useState(null);
    const [profile, setProfile] = useState(null);
    const [order, setOrder] = useState(null);
    const [notifications, setNotifications] = useState(null);
    const [search, setSearch] = useState('');
    const [searchText, setSearchText] = useState(route.query.search ? route.query.search : 'Search');
    const [userImage, setUserImage] = useState("");
    
    const [header] = useState(1);

    //console.log(`API URL::: ${config.API_URL}`);
    // sticky menu

    useEffect(() => {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 4) {
          setScroll(true);
        } else {
          setScroll(false);
        }
      });
    }, []);

    // window resize
    useEffect(() => {
      window.addEventListener("resize", () => {
        if (window.innerWidth >= 1024) {
          setToggle(false);
        }
      });
    });


  
    let scroll;
    if (
      route.pathname === "/item/[item]" ||
      route.asPath === "/home" ||
      route.asPath === "/" 
    ) {
      scroll = true;
    } else {
      scroll = false;
    }



    //  Dynamic navigations                            
    const mobileCollapse = (id) => {
      if (isCollapse === id) {
        return setCollapse(null);
      }
      setCollapse(id);
    };

    const connectWallet = () => {
      if (status === "notConnected") {
        dispath(connectWalletShow());
      }
    };

    var  isConnected;
    if (status === "notConnected") {
      isConnected =false;
    }else if  (status === "connected") {
      isConnected =true;
    }



    function clearSession(){

      ReactSession.set("_id", null);
      ReactSession.set("id", null);
      ReactSession.set("username", null);
      ReactSession.set("wallet", null);
      ReactSession.set("firstName", null);
      ReactSession.set("lastName", null);
      ReactSession.set("email", null);
      ReactSession.set("balance", null);
      ReactSession.set("image", null );
      ReactSession.set("created", null);

    }

      //window.location.href = `/login`;}
    function loadSession(res){ 

      ReactSession.set("_id", res._id);
      ReactSession.set("id", res.id);
      ReactSession.set("username", res.username);
      ReactSession.set("wallet", res.wallet);
      ReactSession.set("firstName", res.firstName);
      ReactSession.set("lastName", res.lastName);
      ReactSession.set("email", res.email);
      ReactSession.set("balance", res.balance);


      const ui = res.image ? config.IMAGE_URL + res.image : "http://www.gravatar.com/avatar/"+res.username+res.wallet+res._id+"?d=retro";
      ReactSession.set("image", ui );
      setUserImage(ui);


      ReactSession.set("created", res.created);

    }

    //  AXIOS REQUEST                            
    const client = axios.create({ baseURL: `${config.API_URL}`});
    const auth = { headers: { Authorization: `Bearer ${config.API_KEY}`}};

    useEffect(()=>{
      setSearch(route.query.search );
    },[route]);


    // GET profile
    React.useEffect(() => {

         

          client.get(`/profile?wallet=${account}`, auth)
          .then((response) => { 
          
              setProfile(response.data); 
              profile?console.log("PROFILE:::"+JSON.stringify(profile)+"_::::_RESPONSE_:::_"+JSON.stringify(response.data)):"";
    
              if(response.data.data.length == 0){
              
             

                setProfile(null); 
                register(response.data);
    
    
              }else{
    
                //alert("USER FOUND");
                const res = response.data.data[0];
                loadSession(res);


                //GET ORDERS ::::::::: API
                 client.get(`/order?user=${res._id}`, auth)
                 .then((response) => { setOrder(response.data); })
                 .catch(error => { console.error(error) });

              
                 client.get(`/order?earner=${res._id}`, auth)
                 .then((response) => { setNotifications(response.data); })
                 .catch(error => { console.error(error) });
                
              }
          })
          .catch(error => { console.error(error) });

  }, [account]);





    function register(res){
      const name = generateUsername();
      const data = {
            id: parseInt(res.count + 1),
            username: name,
            wallet: account,
            image: "http://www.gravatar.com/avatar/"+res.username+res.wallet+res._id+"?d=retro",
            status: "active"
      }

      client.post("/profile", data , auth)
      .then((response) => {
            
            setProfile(response.data); 
            loadSession(response.data.data[0]);
            profile?console.log("PROFILE:::"+JSON.stringify(profile)+"_::::_RESPONSE_:::_"+JSON.stringify(response.data)):"";

      })
      .catch(error => { console.error(error) });

  }

  const changeSearch = (val) => {
    //console.log(content);
    setSearch(val.target.value);

  };
  const searchSubmit = (e) => {
    e.preventDefault();
    if(search){
      window.location.href = `/search/`+search;
    }else{
      setSearchText("What are you looking for?");

    }
    
  }

  return (
    <>
      {/* main desktop menu sart*/}
      <header className={
          `js-page-header z-5 top-0 w-full transition-colors min-h-[120px]  
          ${scroll ? "page-header--transparent fixed" : "bg-white/[1] shadow-xs dark:bg-jacarta-900"}
          ${scroll && isScroll ? " shadow-xl bg-white dark:bg-jacarta-900" : ""}`
        }
      >
        <div className="flex  items-center px-6 pt-4 pb-2 xl:px-24 ">
          <Link className="shrink-0" href="/">
            <a>
              <div className="dark:hidden">
                <Image
                  src={Logo}
                  height={35}
                  width={169}
                  alt=""
                  className="max-h-7 h-auto "
                />
              </div>
              <div className="hidden dark:block">
                <Image
                  src={WhiteLogo}
                  height={35}
                  width={169}
                  alt=""
                />
              </div>
            </a>
          </Link>
          {/* End  logo */}

          {!(scroll && !isScroll) &&
          <form onSubmit={searchSubmit}
            className="relative ml-8 mr-6 hidden basis-3/12 lg:block "
          >
            <input
              type="search"
              onChange={changeSearch}
              value={search}
              className="dark:focus:ring-jacarta-100 dark:click:ring-jacarta-100 border-jacarta-200 dark:border-jacarta-700 focus:border-jacarta-900 w-full rounded-full border py-2 px-4 pl-4  bg-white/[.15] "
              placeholder={searchText}
            /> 
           
            <button className="hover:bg-accent-dark font-display bg-accent absolute top-0 bottom-0 right-0 rounded-full px-4 py-2 text-sm text-white">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className=" h-4 w-4 fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
              </svg>
                      </button>
          </form>
          
          }
          {/* End Desktop search form */}


          <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 ml-auto items-center lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
            <nav className="navbar w-full">
              <ul className="flex flex-col lg:flex-row">
                {/* home */}

                

                {!isConnected&&
                <>
                <li className="group flex items-center">
                <Link href="/category/browse">
                  <a>
                    <button className="font-display hover:text-accent focus:text-accent flex items-center justify-between text-base text-jacarta-700 dark:text-white lg:px-5">
                      <span>
                        Services
                      </span>
                    </button>
                  </a>
                </Link>
                </li>

                <li className="group flex items-center mr-6">
                <Link href="/support">
                  <a>
                    <button className="font-display hover:text-accent focus:text-accent flex items-center justify-between text-base text-jacarta-700 dark:text-white lg:px-5">
                      <span>
                        Support
                      </span>
                    </button>
                  </a>
                </Link>
                </li>


                <li className="group flex items-center mr-6">
                  <button  onClick={connectWallet} className="px-4 py-2 js-wallet border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex items-center justify-center rounded-full  bg-accent text-white transition-colors border-transparent focus:border-transparent dark:border-transparent">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} className="h-4 w-4 transition-colors fill-white dark:fill-white">
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
                        </svg> &nbsp;
                        Connect Wallet
                  </button>
                </li>
                </>
                }
                
                
              

                {isConnected&&

                
            
                <>
               
                <li className="js-nav-dropdown group relative  flex items-center justify-between px-4">


                  <NotificationsNoneIcon/>

                 
                  <ul className="dropdown-menu dark:bg-jacarta-800 text-center !-right-4 !top-[85%] !left-auto  z-30 hidden min-w-[400px] gap-x-4 
                    border-t border-jacarta-50 rounded-xl shadow-xl overflow-hidden   transition-all group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:block lg:translate-y-4 lg:opacity-0
                    lg:shadow-2xl lg:group-hover:translate-y-2 hidden relative">

                      <div className="flex p-4 bg-white dark:bg-jacarta-900 items-center  z-6 shadow-md">
                        <NotificationsNoneIcon className="h-5 w-5 text-jacarta-400"/>
                        <p ><strong> &nbsp;Notifications (3)</strong></p>
                      </div>
                      <ul className="pane bg-white dark:bg-jacarta-900">
                     
                      {notifications?.data.sort((a, b) => b.id - a.id).map((item) => {
                          const {
                            _id,
                            id,
                            code,
                            service,
                            user,
                            amount,
                            status
                          } = item;


                          
                          return (
                          
                              <li key={id} className="p-4 flex w-full justify-between bg-white dark:bg-jacarta-800 ">
                                <figure className="flex">
                                  <Link href={`/order/${_id}`}>
                                    <a className="relative block max-w-[50px]">
                                      <img
                                        src={service.image ? config.IMAGE_URL+"/"+service.image : config.IMAGE_URL+"/"+config.DEFAULT_USER_IMAGE}
                                        alt=""
                                        loading="lazy"
                                        className="rounded-md"
                                      />
                                     
                                    </a>
                                  </Link>
                                  <div className="ml-4 text-left">
                                  
                                    <Link href={`/order/${_id}`}>
                                      <a className="mt-0 hover:text-accent  ">
                                        <p className="text-jacarta-400 text-sm mt-0 dark:text-white">
                                          {service.title}
                                        </p>
                                        <span className="font-bold text-jacarta-500 text-xs">
                                          {service.user.username}
                                         
                                        </span>
                                        {status=='Completed'&& <span className="text-xs text-green ml-2">{status}</span> }
                                        {status=='started'&& <span className="text-xs text-accent ml-2">{status}</span> }
                                        {status=='Cancelled'&& <span className="text-xs text-red ml-2">{status}</span> }
                                      </a>
                                    </Link>
                                  </div>
                                </figure>
                              </li>
                          );
                          })}
                      </ul>
                  </ul>
                </li>
                

                <li className="js-nav-dropdown group relative  flex items-center justify-between px-4">


                  <MailOutlineIcon/>

                  <ul className="dropdown-menu dark:bg-jacarta-800 text-center !-right-4 !top-[85%] !left-auto  z-30 hidden min-w-[400px] gap-x-4 
                    border-t border-jacarta-50 rounded-xl shadow-xl overflow-hidden   transition-all group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:block lg:translate-y-4 lg:opacity-0
                    lg:shadow-2xl lg:group-hover:translate-y-2 hidden relative">
                      <div className="flex p-4 bg-white dark:bg-jacarta-800 items-center  z-6 shadow-md">
                        <MailOutlineIcon className="h-5 w-5 text-jacarta-400"/>
                        <p ><strong> &nbsp;Inbox (3)</strong></p>
                      </div>
                      <ul className="pane dark:bg-jacarta-800">
                      {inbox_data
                        .map((item) => {
                          const {
                            image,
                            title,
                            id,
                            likes,
                            text,
                            owner,
                            creatorImage,
                            ownerImage,
                            creatorname,
                            ownerName,
                            price,
                            auction_timer,
                          } = item;
                          return (
                          
                              <li key={id} className="p-4 flex w-full justify-between bg-white dark:bg-jacarta-800 ">
                                <figure className="flex">
                                  <Link href={`/profile/${owner.id}`}>
                                    <a className="relative block min-w-[60px]">
                                      <img
                                        src={owner.image}
                                        alt={owner.name}
                                        className="rounded-full h-14 w-14"
                                        loading="lazy"
                                      />
                                      <div
                                        className="dark:border-jacarta-600 bg-green absolute -right-0 top-[30px] flex h-3 w-3 rounded-full border-2 border-white"
                                        data-tippy-content="Verified Collection"
                                      >
                                      
                                      </div>
                                    </a>
                                  </Link>
                                  <div className="ml-4 text-left">
                                  
                                    <Link href={`/profile/${owner.id}`}>
                                      <a className="mt-0 hover:text-accent  ">
                                        <span className="font-bold">
                                          {owner.name}
                                        </span>
                                        <p className="text-jacarta-400 text-sm mt-0 dark:text-white">
                                          {text}
                                        </p>
                                      </a>
                                    </Link>
                                    
                                  
                                  </div>
                                </figure>
                                
                                <div className="ml-4 justify-end">
                                  <button
                                    className="dark:bg-jacarta-700-dark hover:text-accent flex py-2 px-2 shrink-0 align-center justify-center "
                                  >
                                    <MailOutlineIcon className="h-5 w-5"/>
                                  </button>
                                </div>
                                
                              </li>
                            



                          );
                          })}
                        </ul>
                  </ul>
                </li>

                <li className=" group relative  flex items-center justify-between px-4">
                  <Link href="/profile/lists">
                    <FavoriteBorderIcon/>
                  </Link>
                </li>

                <li className="js-nav-dropdown group relative  flex items-center justify-between px-4">
                  <button className="font-display flex items-center justify-between text-base text-jacarta-700 dark:text-white lg:px-5">
                        <span>Orders</span>
                  </button>
                  
                  <ul className="dropdown-menu dark:bg-jacarta-800 text-center !-right-4 !top-[85%] !left-auto  z-30 hidden min-w-[400px] gap-x-4 
                    border-t border-jacarta-50 rounded-xl shadow-xl overflow-hidden   transition-all group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:block lg:translate-y-4 lg:opacity-0
                    lg:shadow-2xl lg:group-hover:translate-y-2 hidden relative">
                      <div className="flex p-4 bg-white dark:bg-jacarta-800 justify-between z-6 shadow-md">
                        <div className="flex  items-center ">
                          <MailOutlineIcon className="h-5 w-5 text-jacarta-400"/>
                          <p ><strong> &nbsp;Orders (3)</strong></p>
                        </div>

                        <a href="/profile?show=orders" className="hover:text-accent" > See all orders </a>
                      </div>
                      <ul className="pane dark:bg-jacarta-800">

                        {order?.data.sort((a, b) => b.id - a.id).map((item) => {
                          const {
                            _id,
                            id,
                            code,
                            service,
                            user,
                            amount,
                            status
                          } = item;


                          
                          return (
                          
                              <li key={id} className="p-4 flex w-full justify-between bg-white dark:bg-jacarta-800 ">
                                <figure className="flex">
                                  <Link href={`/order/${_id}`}>
                                    <a className="relative block max-w-[50px]">
                                      <img
                                        src={service.image ? config.IMAGE_URL+"/"+service.image : config.IMAGE_URL+"/"+config.DEFAULT_USER_IMAGE}
                                        alt=""
                                        loading="lazy"
                                        className="rounded-md"
                                      />
                                     
                                    </a>
                                  </Link>
                                  <div className="ml-4 text-left">
                                  
                                    <Link href={`/order/${_id}`}>
                                      <a className="mt-0 hover:text-accent  ">
                                        <p className="text-jacarta-400 text-sm mt-0 dark:text-white">
                                          {service.title}
                                        </p>
                                        <span className="font-bold text-jacarta-500 text-xs">
                                          {service.user.username}
                                         
                                        </span>
                                        {status=='Completed'&& <span className="text-xs text-green ml-2">{status}</span> }
                                        {status=='started'&& <span className="text-xs text-accent ml-2">{status}</span> }
                                        {status=='Cancelled'&& <span className="text-xs text-red ml-2">{status}</span> }
                                      </a>
                                    </Link>
                                  </div>
                                </figure>
                              </li>
                          );
                          })}
                        </ul>
                  </ul>
                </li>
                <li className="group flex items-center mr-2">
                  <Link href={`/service/post/`}>
                  <button  onClick={connectWallet} className="px-4 py-2 js-wallet border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex items-center justify-center rounded-full  bg-accent text-white transition-colors border-transparent focus:border-transparent dark:border-transparent">
                        <TextSnippetIcon className="mr-4" width={24} height={24}/>
                        Post a Service
                  </button>
                  </Link>
                </li>
                
                </>
                }


           

           {isConnected&&
            <li className="ml-4 hidden items-center lg:flex xl:ml-4">

              

              <div className="js-nav-dropdown group-dropdown relative">
                  
                <a href="/profile" className=" px-4 py-2 dropdown-toggle border-jacarta-100 hover:bg-white focus:bg-white group dark:hover:bg-accent ml-2 flex px-2  items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]">
                  
                  <img className="h-7 w-7 rounded-full mr-2" src={ReactSession.get("image")} /> 
                  {ReactSession.get("username")}
                  <span className="text-jacarta-200 mr-2 ml-2 -mt-1">|</span>
                      <svg className="icon icon-ETH ml-2 mr-1 h-[1.125rem] w-[1.125rem]">
                        <use xlinkHref="/icons.svg#icon-ETH" />
                      </svg>
                      <span className=" text-sm font-bold">
                        0 ETH
                      </span>
                </a>
                <div className="dropdown-menu dark:bg-jacarta-800 group-dropdown-hover:opacity-100 group-dropdown-hover:visible !right-2 !top-[85%] !left-auto z-10 min-w-[300px] whitespace-nowrap rounded-2.5xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl hidden lg:invisible lg:opacity-0">

                  <div className="dark:border-jacarta-600 border-jacarta-100 mx-2 mb-6 rounded-lg border p-4">
                    <span className="dark:text-jacarta-200 text-sm font-medium tracking-tight">
                      Balance
                    </span>
                    <div className="flex items-center">
                      <svg className="icon icon-ETH -ml-1 mr-1 h-[1.5rem] w-[1.5rem]">
                        <use xlinkHref="/icons.svg#icon-ETH" />
                      </svg>
                      <span className="text-accent text-xl font-bold">
                        0 ETH
                      </span>
                    </div>
                  </div>
                 
                  <Link href="/profile">
                    <a className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">

                      <PersonOutlineOutlinedIcon width={24} height={24}/>


                      <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">
                        My Profile 
                      </span>
                    </a>
                  </Link>

                  <Link href="/settings">
                    <a className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">
                      <SettingsOutlinedIcon width={24} height={24}/>
                      <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">
                        Settings
                      </span>
                    </a>
                  </Link>

              
                  <Link href="/service/post">
                    <a className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">
                      <TextSnippetOutlinedIcon width={24} height={24}/>
                      <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">
                        Post a Service
                      </span>
                    </a>
                  </Link>


                  
                  <hr className="mt-5"/>

                  <Link href="/support/">
                    <a className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">
                      <HelpOutlineOutlinedIcon width={24} height={24}/>
                      <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">
                        Help & Support
                      </span>
                    </a>
                  </Link>
                  
                  <hr className="mt-5"/>
                    <button className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">
                      <LogoutOutlinedIcon width={24} height={24}/>
                      <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">
                        Sign out
                      </span>
                    </button>
                </div>
              </div>
            </li>
          }
          <DarkMode />
          </ul>
          </nav>

          </div>
          
          {/* header menu conent end for desktop */}

          <div className="ml-auto flex lg:hidden">
            <Link href="/profile/user_avatar">
              <a
                className="border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                aria-label="profile"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                </svg>
              </a>
            </Link>
            <DarkMode />
            <button
              className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
              aria-label="open mobile menu"
              onClick={() => setToggle(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
              </svg>
            </button>
          </div>
          {/* End header right content  for mobile */}
        </div>
        {/* End flex item */}

       

          <HeaderCategories scroll={scroll}/>
            
        
          
          
      {/* header menu conent end for desktop */}


      </header>
      {/* main desktop menu end */}

      {/* start mobile menu and it's other materials  */}
      <div
        className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center  lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
          toggle ? "nav-menu--is-open" : "hidden"
        }`}
      >
        <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
          <div className="dark:hidden">
            <Image
              src={Logo}
              height={28}
              width={130}
              className="max-h-7 h-auto "
            />
          </div>

          <div className="hidden dark:block">
            <Image
              src={WhiteLogo}
              height={28}
              width={130}
              alt="Xhibiter | NFT Marketplace"
            />
          </div>

          <button
            className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            onClick={() => setToggle(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
            </svg>
          </button>
        </div>
        {/* mobile menu top header content */}

        <form action="search" className="relative mt-24 mb-8 w-full lg:hidden">
          <input
            type="search"
            className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-3 px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
            placeholder="Search"
          />
          <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-500 h-4 w-4 dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
            </svg>
          </span>
        </form>
        {/* End search form mobile menu  */}

        <nav className="navbar w-full">
          <ul className="flex flex-col lg:flex-row">
            
           
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/create">
                <a>
                  <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive("/create", route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      Create
                    </span>
                  </button>
                </a>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      {/* End mobile menu and it's other materials */}
    </>
  );
}
