import { display } from "@mui/system";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { connectWalletHide } from "../../redux/counterSlice";

import WalletButton from "../wallet-btn/WalletButton";

import Link from "next/link";


const Connect_wallet = () => {
  const connectWallet = useSelector((state) => state.counter.connectWallet);
  const dispatch = useDispatch();
  return (
    <div>
      {/* <!-- Wallet Modal --> */}
      <div
        className={connectWallet ? "block modal fade show " : "modal fade hidden"}
      >
        <div className="modal-dialog max-w-lg">
          <div className="modal-content">
            <div className="modal-header text-center">
                <div className="w-full">
                    <h5 className="modal-title" id="connectWalletLabel">
                        Connect your wallet
                    </h5>
                    <p className="text-md font-normal text-jacarta-400 pt-2">
                            Choose one of the available wallet providers or create a new wallet.  
                            &nbsp;<a href="/support" className="text-accent">What is a wallet? </a>
                    </p>
                    <button type="button" className="btn-close" onClick={() => dispatch(connectWalletHide())}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="fill-jacarta-700 h-6 w-6 dark:fill-white">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                        </svg>
                    </button>
                </div>
            </div>
           
            {/* <!-- Body --> */}
            <WalletButton/>
            {/* <!-- end body --> */}

            <div className="modal-footer">
                <Link className="shrink-0" href="#"><a>Show more options</a></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connect_wallet;
