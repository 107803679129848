const inbox_data = [
	// bids data
	{
		id: '1',
		image: '/images/products/item_1.jpg',
		title: 'I will create shopify store,shopify website and dropshipping store',
		price: 250,
		likes: 159,
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "9",
			name: "Jonothan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		category: "web",
	},
	{
		id: '2',
		image: '/images/products/item_2.jpg',
		title: 'I will create shopify dropshipping ecommerce stores design making money on autopilot',
		price: 155,
		likes: 159,
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "8",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "web",
	},
	{
		id: '3',
		image: '/images/products/item_3.jpg',
		title: 'I will build shopify store or dropshipping ecommerce store',
		price: 150,
		likes: 159,
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "7",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "web",
	},
	{
		id: '4',
		image: '/images/products/item_4.jpg',
		title: 'I will design and develop a fully responsive wordpress website',
		price: 100,
		likes: 159,
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "6",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "web",
	},
	{
		id: '5',
		image: '/images/products/item_5.jpg',
		title: 'I will build your professional wix website with unlimited revisions',
		price: 125,
		likes: 159,
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "5",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "web",
	},
	{
		id: '6',
		image: '/images/products/item_6.jpg',
		title: 'I will develop, design and kickstart your ecommerce shopify website',
		price: 135,
		likes: 159,
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "4",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "web",
	},
	
];

export { inbox_data };
