import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import axios from "axios";
import config from "../../utils/config.js";

export default function HeaderCategories({scroll}) {

    const route = useRouter();
    const [isScroll, setScroll] = useState(false);  

    const [categories, setCategories] = React.useState(null);
    const [error, setError] = React.useState(null);

    //  AXIOS REQUEST                            
    const client = axios.create({ baseURL: `${config.API_URL}`});
    const auth = { headers: { Authorization: `Bearer ${config.API_KEY}`}};


    // GET category
    React.useEffect(() => {
    client.get(`/category`, auth)
    .then((response) => { setCategories(response.data); })
    .catch(error => { console.error(error) });
    }, []);


    // sticky menu
    useEffect(() => {
        window.addEventListener("scroll", () => {
        if (window.pageYOffset > 4) {
            setScroll(true);
        } else {
            setScroll(false);
        }
        });
    }, []);




    return (
        <>
        {!(scroll && !isScroll) &&
        <div className="flex items-center px-6 py-0 xl:px-24 js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 ml-auto justify-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
            <nav className="navbar w-full max-w-[91rem] ">
              <ul className="flex flex-col lg:flex-row justify-between">
                {/* home */}
                {categories?.data.map((item) => {
                  const { id, icon, name, parent, desc, sub } = item;
                  return (
                    <>
                    {!parent &&
                    <>
                    {/* Menu Item -------------*/}
                    <li className="js-nav-dropdown group relative mr-4" key={id}>
                      <a className="dropdown-toggle hover:text-accent focus:text-accent flex items-center justify-between py-3.5 dark:text-white lg:px-4 w-full">
                        {name}
                      </a>
                        {/* Sub Menu  -------------*/}
                      <ul className="dropdown-menu z-8 dark:bg-jacarta-800 left-0 top-[85%] hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl-bottom bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative">

                        {
                         categories?.data.sort((a, b) => a.id - b.id).map((sub) => {
                          const { _id, id, icon, name, parent } = sub;
                          return (
                            <>
                              {parent?._id==item._id &&
                              <li className="px-5 pr-8 py-2">
                                  <a  href={`/category/${_id}`} className="hover:text-accent focus:text-accent">
                                    {name}
                                  </a>
                              </li> 
                              }
                            </>
                            );
                          })}
                      </ul>
                    </li>
                    </>
                    }    
                    </>
                  );
                })}
              </ul>
            </nav>
            {/* End menu for desktop */}
          </div>
        }
        </>
    );

};